export default (theme) => ({
    root: {
        borderRadius: theme.common.roundiness,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        textDecoration: 'none',
        color: theme.palette.white,
        fontSize: 20,
        fontWeight: 500,

        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            textAlign: 'right',
            justifyContent: 'flex-end',
        },
    },
});

export default (theme) => ({
    root: {
        padding: '2.5rem 4.75rem',
        '& h3': {
            fontSize: '1.1rem',
            color: theme.palette.primary.main,
        },
    },
    ombControlNumber: {
        alignItems: 'center',
        padding: '0.5rem 0 2.5rem',
        justifyContent: 'center',
    },
    spacer: {
        color: theme.palette.darkGray,
        paddingLeft: 13,
        paddingRight: 13,
    },
});

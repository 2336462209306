import { createSelector } from "reselect";
import { transformTokensFormDataForResultsScreen } from "./transforms";

export const getTokensFormState = (state) => state.tokensForm;

export const isSubmitInProgress = createSelector(
    getTokensFormState,
    (tokensFormState = {}) => tokensFormState.submitInProgress,
);

export const getTokenFormData = createSelector(
    getTokensFormState,
    (tokensFormState = {}) => {
        // Get all the data from selectors that we need to provide as props to `TokensResultsScreen`
        return transformTokensFormDataForResultsScreen(tokensFormState);
    }
);

export const getValidTokens = createSelector(
    getTokensFormState,
    (tokensFormState = {}) => tokensFormState.validTokens,
);

export default (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
});

const PREFIX = 'TokensForm/';

export const STORE_TOKENS = `${PREFIX}STORE_TOKENS`;
export const storeTokens = ({validTokens = [], invalidTokens = []}) => ({
    type: STORE_TOKENS,
    validTokens,
    invalidTokens,
});

export const SUBMIT_TOKENS_REQUESTED = `${PREFIX}SUBMIT_TOKENS_REQUESTED`;
export const SUBMIT_TOKENS_SUCCEEDED = `${PREFIX}SUBMIT_TOKENS_SUCCEEDED`;
export const SUBMIT_TOKENS_FAILED = `${PREFIX}SUBMIT_TOKENS_FAILED`;

export const submitTokensRequested = (tokens) => ({
    type: SUBMIT_TOKENS_REQUESTED,
    tokens,
});

export const submitTokensSucceeded = (createdTokens, invalidTokens) => ({
    type: SUBMIT_TOKENS_SUCCEEDED,
    createdTokens,
    invalidTokens
});

export const submitTokensFailed = (error) => ({
   type: SUBMIT_TOKENS_FAILED,
   error,
});

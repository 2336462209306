import {
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography,
    withStyles,
} from '@material-ui/core';
import { CloseRounded as CloseIcon } from '@material-ui/icons';
import {
    faExclamationTriangle,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { VARIANT_WARNING, VARIANT_DANGER } from './constants';
import clsx from 'clsx';

const ICON_VARIANTS = {
    [VARIANT_WARNING]: (
        <FontAwesomeIcon icon={faExclamationTriangle} size="3x" />
    ),
    [VARIANT_DANGER]: <FontAwesomeIcon icon={faTimesCircle} size="3x" />,
};

const AlertBanner = (props) => {
    const {
        classes,
        children,
        contentInline,
        variant,
        title,
        textLine1,
        textLine2,
        textExtra,
        ActionButton1,
        ActionButton2,
        closeButton,
        CloseButtonProps,
        ...restProps
    } = props;

    const cardContentProps = {
        wrap: 'wrap',
        spacing: 3,
    };
    const mainContentProps = { direction: 'column' };
    const textContainerProps = { xs: closeButton ? 11 : 12 };
    const actionContainerProps = { xs: 12 };

    if (contentInline) {
        cardContentProps.wrap = 'nowrap';
        cardContentProps.spacing = 6;
        mainContentProps.direction = 'row';
        if (ActionButton1 && ActionButton2) {
            textContainerProps.xs = 4;
            actionContainerProps.xs = 6;
        } else if (ActionButton1 || ActionButton2) {
            textContainerProps.xs = 8;
            actionContainerProps.xs = 3;
        }
    }

    const _renderTextLine2 = () => {
        if (!textLine2) {
            return null;
        }
        if (typeof textLine2 === 'string') {
            return (
                <Grid item component={Typography}>
                    {textLine2}
                </Grid>
            );
        }
        return textLine2;
    };

    return (
        <Card
            classes={{
                root: classes[`root${variant}`],
            }}
            {...restProps}
        >
            <CardContent>
                <Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    className={classes.cardContent}
                    {...cardContentProps}
                >
                    <Grid item className={classes.icon}>
                        {ICON_VARIANTS[variant]}
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title}>
                            {title}
                        </Typography>
                    </Grid>
                    {closeButton && (
                        <Grid item className={classes.closeButton}>
                            <IconButton {...CloseButtonProps}>
                                <CloseIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                    )}
                    <Grid item container {...mainContentProps}>
                        <Grid
                            container
                            item
                            direction="column"
                            justify="center"
                            className={classes.textContainer}
                            {...textContainerProps}
                        >
                            {!!textLine1 && (
                                <Grid
                                    item
                                    component={Typography}
                                    style={{ fontWeight: 700 }}
                                >
                                    {textLine1}
                                </Grid>
                            )}
                            {_renderTextLine2()}
                        </Grid>
                        {(ActionButton1 || ActionButton2) && (
                            <Grid
                                container
                                item
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                className={clsx({
                                    [classes.actionContainer]: contentInline,
                                    [classes.actionContainerBlock]: !contentInline,
                                })}
                                {...actionContainerProps}
                            >
                                {ActionButton1 && (
                                    <Grid item>{ActionButton1}</Grid>
                                )}
                                {ActionButton2 && (
                                    <Grid item>{ActionButton2}</Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export const propTypes = {
    classes: PropTypes.object.isRequired,

    // Title to render next to the icon in the banner
    title: PropTypes.string.isRequired,

    children: PropTypes.node,

    // The kind of banner; dictates the colors and icon
    variant: PropTypes.string,

    // Whether the banner contents should be arranged in a row ("inline") or column ("stacked")
    contentInline: PropTypes.bool,

    // First, bold line of text in banner
    textLine1: PropTypes.string,
    // Second, plain line of text in banner. Can also be a Grid item Typography component.
    textLine2: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    // Extra text displayed to the right of textLine2
    textExtra: PropTypes.string,

    // Action buttons that are displayed to the right (or below) the text
    ActionButton1: PropTypes.node,
    ActionButton2: PropTypes.node,

    // Whether to include a close button in the top right of the banner
    closeButton: PropTypes.bool,

    // Props that are applied to the close button
    CloseButtonProps: PropTypes.object,
};

AlertBanner.propTypes = propTypes;

AlertBanner.defaultProps = {
    variant: VARIANT_WARNING,
    contentInline: true,
    children: null,
    textLine1: '',
    textLine2: '',
    textExtra: '',
    ActionButton1: null,
    ActionButton2: null,
    closeButton: false,
    CloseButtonProps: {},
};

export default withStyles(styles)(AlertBanner);

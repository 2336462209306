import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import Button, { VARIANT_TEXT } from '../../common/Button';
import styles from './styles';
import DotLogo from './DotLogo';

class Footer extends Component {
    render() {
        const {
            classes,
            leftHeadingText,
            leftTextLine1,
            leftTextLine2,
            leftTextLine3,
            leftTextLine4,
            rightLinkText1,
            rightLink1,
            rightLinkText2,
            rightLink2,
        } = this.props;
        return (
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                className={classes.root}
            >
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item component={DotLogo} />
                        <Grid item>
                            <Typography className={classes.text}>
                                {leftHeadingText}
                            </Typography>
                            <Typography className={classes.text}>
                                {leftTextLine1}
                            </Typography>
                            <Typography className={classes.text}>
                                {leftTextLine2}
                            </Typography>
                            <Typography className={classes.text}>
                                {leftTextLine3}
                            </Typography>
                            <Typography className={classes.text}>
                                {leftTextLine4}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="flex-end">
                        <Grid item className={classes.firstRightLink}>
                            <Button
                                variant={VARIANT_TEXT}
                                href={rightLink1}
                                target="_blank"
                                rel="noopener noreferrer"
                                disableRipple
                                disableFocusRipple
                            >
                                {rightLinkText1}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={VARIANT_TEXT}
                                href={rightLink2}
                                target="_blank"
                                rel="noopener noreferrer"
                                disableRipple
                                disableFocusRipple
                            >
                                {rightLinkText2}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

Footer.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    leftHeadingText: PropTypes.string.isRequired,
    leftTextLine1: PropTypes.string.isRequired,
    leftTextLine2: PropTypes.string.isRequired,
    leftTextLine3: PropTypes.string.isRequired,
    leftTextLine4: PropTypes.string.isRequired,

    rightLinkText1: PropTypes.string.isRequired,
    rightLink1: PropTypes.string.isRequired,
    rightLinkText2: PropTypes.string.isRequired,
    rightLink2: PropTypes.string.isRequired,
};

Footer.defaultProps = {
    leftHeadingText: 'U.S. Department of Transportation',
    leftTextLine1: 'Federal Aviation Administration',
    leftTextLine2: '800 Independence Avenue, SW',
    leftTextLine3: 'Washington, DC 20591',
    leftTextLine4: '(866) TELL-FAA | (866) 835-5322',

    rightLinkText1: 'Privacy Policy',
    rightLink1: 'https://www.transportation.gov/dot-website-privacy-policy',
    rightLinkText2: 'Accessibility',
    rightLink2: 'https://www.transportation.gov/accessibility',
};

export default withStyles(styles)(Footer);

export const ROUTE_HOME = '/home';
export const ROUTE_RESULTS = '/results';
export const ROUTE_LOGOUT = '/logout';
export const ROUTE_LOGIN = '/login';
export const ROUTE_REGISTER = '/register';

export const APP_TITLE = 'UAS Trust';
export const ROUTE_TITLE_HOME = 'UAS Trust - Submit new tokens';
export const ROUTE_TITLE_RESULTS = 'UAS Trust - Token Submission Results';
export const ROUTE_TITLE_LOGIN = 'UAS Trust - Login';
export const ROUTE_TITLE_REGISTER = 'UAS Trust - Register an Account';

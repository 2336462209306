export const PREFIX = 'init/';
export const CONFIG_VALUES_PULLED_AND_SET = `${PREFIX}CONFIG_VALUES_PULLED_AND_SET`;
export const INITIALIZATION_SUCCEEDED = `${PREFIX}INITIALIZATION_SUCCEEDED`;
export const INITIALIZATION_FAILED = `${PREFIX}INITIALIZATION_FAILED`;

export const configValuesPulledAndSet = (oktaConfig) => ({
    type: CONFIG_VALUES_PULLED_AND_SET,
    oktaConfig,
});

export function initializationSucceeded() {
    return {
        type: INITIALIZATION_SUCCEEDED,
    };
}

export function initializationFailed(errors = null) {
    return {
        type: INITIALIZATION_FAILED,
        payload: {
            errors,
        },
    };
}

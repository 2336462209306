import { all, call, put, takeLatest } from 'redux-saga/effects';
import { fetchWithAuth } from '../store/api/sagas';
import {
    FETCH_USER_REQUESTED,
    fetchUserFailed,
    fetchUserSucceeded,
    REGISTER_USER_REQUESTED,
    registerUserSucceeded,
    registerUserFailed,
    FETCH_TA_REQUESTED,
    fetchTAFailed,
    fetchTASucceeded,
} from './actions';
import { FAILED_GENERIC_ERROR_MSG } from './constants';

export function* fetchUser({ userSearch }) {
    try {
        const response = yield call(fetchWithAuth, 'searchUser', {
            method: 'POST',
            body: userSearch,
        });
        const { data: { items = [] } = {}, error = {} } = response;
        if (error.message || !items.length) {
            yield put(
                fetchUserFailed(
                    error.message ? error.message : FAILED_GENERIC_ERROR_MSG,
                ),
            );
        } else {
            yield put(fetchUserSucceeded(items[0]));
        }
    } catch (error) {
        yield put(fetchUserFailed(FAILED_GENERIC_ERROR_MSG));
    }
}

export function* registerUser({ user }) {
    try {
        const response = yield call(fetchWithAuth, 'registerUser', {
            method: 'POST',
            body: user,
        });
        const { data: { items = [] } = {}, error = {} } = response;
        if (error.message || !items.length) {
            yield put(
                registerUserFailed(
                    error.message ? error.message : FAILED_GENERIC_ERROR_MSG,
                ),
            );
        } else {
            alert('Success: Your registration request has been submitted!');
            yield put(registerUserSucceeded(items[0]));
        }
    } catch (error) {
        yield put(registerUserFailed(FAILED_GENERIC_ERROR_MSG));
    }
}

export function* fetchTA({ userSearch }) {
    try {
        const response = yield call(fetchWithAuth, 'searchTestAuthority', {
            method: 'POST',
            body: userSearch,
        });
        const { data: { items = [] } = {}, error = {} } = response;
        if (error.message || !items.length) {
            yield put(
                fetchTAFailed(
                    error.message ? error.message : FAILED_GENERIC_ERROR_MSG,
                ),
            );
        } else {
            yield put(fetchTASucceeded(items[0]));
        }
    } catch (error) {
        yield put(fetchTAFailed(FAILED_GENERIC_ERROR_MSG));
    }
}

export default function* () {
    yield all([
        takeLatest(FETCH_USER_REQUESTED, fetchUser),
        takeLatest(REGISTER_USER_REQUESTED, registerUser),
        takeLatest(FETCH_TA_REQUESTED, fetchTA),
    ]);
}

export default (theme) => ({
    paper: {
        backgroundColor: theme.palette.white,
        padding: '5px 15px',

        [theme.breakpoints.up('xl')]: {
            padding: '30px 300px',
        },
        [theme.breakpoints.down('xl')]: {
            padding: '30px 15vw',
            maxWidth: 'calc(650px + 30vw)',
        },
        [theme.breakpoints.down('md')]: {
            padding: '30px 5vw',
            maxWidth: '90vw',
        },
    },
    actionButtons: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
        },
    },
    submitButton: {
        marginLeft: 'auto',
    },
    title: {
        fontSize: 54,
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: 26,
        },
    },
});

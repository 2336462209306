export default (theme) => {
    const fieldLabel = {
        fontSize: 20,
        fontWeight: 500,
        color: theme.palette.black,
        marginBottom: 10,
    };
    const { fontFamily } = theme;
    return {
        root: {},
        input: {
            padding: '20px 25px',
            fontSize: 20,
            fontWeight: 500,
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.lightGray,
            wordWrap: 'break-word', // prevents long unbroken values exceeding container in IE11
            fontFamily,
            '&:disabled': {
                opacity: 0.5,
            },
        },
        moreOpaqueDisabledInput: {
            '&:disabled': {
                opacity: 0.75,
            },
        },
        highContrastPlaceholder: {
            '&::placeholder': {
                opacity: 0.75,
            },
            '&:disabled::placeholder': {
                opacity: 1,
            },
        },
        fieldLabel,
        errorFieldLabel: {
            ...fieldLabel,
            color: theme.palette.error.main,
        },
        multiline: {
            backgroundColor: theme.palette.lightGray,
            padding: 0,
            margin: 0,
        },
    };
};

const titleIcon = {
    height: 64,
    width: 'unset',
    marginTop: 5,
};

export default (theme) => ({
    root: {
        margin: '2% 0',
        padding: '5%',
        backgroundColor: theme.palette.white,
    },
    topGrid: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    title: {
        fontSize: 54,

        [theme.breakpoints.down('md')]: {
            fontSize: 32,
        },
    },
    successIcon: {
        ...titleIcon,
        fill: theme.palette.success.main,
    },
    failIcon: {
        ...titleIcon,
        fill: theme.palette.error.main,
    },
    errors: {
        width: '70%',
        color: theme.palette.darkGray,
        backgroundColor: theme.palette.lightGray,
        borderColor: theme.palette.lightGray,
        borderRadius: 5,
        padding: 20,
        lineHeight: 1.75,

        [theme.breakpoints.down('md')]: {
            width: '85%',
        },
    },
    button: {
        marginTop: 30,
        marginRight: '25%',
    },
    printPrompt: {
        marginTop: -10,
        paddingTop: 0,
    },
    commonReasons: {
        fontWeight: 900,
        paddingTop: 25,
    },
    contentTypography: {
        paddingLeft: 20,
    },
});

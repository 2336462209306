import React from 'react';
import {
    SUCCESS_TITLE,
    ERROR_TITLE,
    ERROR_THRESHOLD_EXCEEDED_TITLE,
} from '../TokensResultsScreen/constants';
import { ERROR_COUNT_THRESHOLD } from './constants';
import { pluralize } from '../../utils/strings';

export const getResultsTitle = ({ createdTokens, invalidTokens, error }) => {
    if (
        (invalidTokens && invalidTokens.length) ||
        error ||
        !createdTokens ||
        !createdTokens.length
    ) {
        return invalidTokens.length >= ERROR_COUNT_THRESHOLD
            ? ERROR_THRESHOLD_EXCEEDED_TITLE
            : ERROR_TITLE;
    } else {
        return SUCCESS_TITLE;
    }
};

export const getErrorCount = (invalidTokens = []) => {
    return invalidTokens.reduce((accumulatedTotal, invalidToken = {}) => {
        const { messages = [] } = invalidToken;
        return accumulatedTotal + messages.length;
    }, 0);
};

export const translateMessage = (message = '') => {
    return message === 'token_id must be unique' ? 'Duplicate tokens' : message;
};

export const getErrors = (invalidTokens = []) => {
    return invalidTokens.reduce((accumulatedErrors, invalidToken = {}) => {
        const { token = '', messages = [] } = invalidToken;
        return [
            ...accumulatedErrors,
            ...messages.map(
                (message) => `${token} - ${translateMessage(message)}`,
            ),
        ];
    }, []);
};

export const getResultsSummary = ({ createdTokens, invalidTokens, error }) => {
    if (
        (invalidTokens && invalidTokens.length) ||
        error ||
        !createdTokens ||
        !createdTokens.length
    ) {
        return getErrorCount(invalidTokens) >= ERROR_COUNT_THRESHOLD ? (
            <>
                Your CSV file has encountered errors. Please check your CSV file
                and resubmit.{' '}
            </>
        ) : (
            <>
                There was a problem receiving some of your tokens.{' '}
                <strong> {pluralize('token', createdTokens.length)} </strong>
                successfully submitted.{' '}
                <strong>
                    {' '}
                    {pluralize('token', invalidTokens.length)}{' '}
                </strong>{' '}
                had errors.{' '}
            </>
        );
    } else {
        return (
            <>
                {' '}
                Congratulations, you successfully submitted{' '}
                <strong>{pluralize('token', createdTokens.length)}</strong> to
                the system.
            </>
        );
    }
};

export const transformTokensFormDataForResultsScreen = (formState) => {
    const { createdTokens, invalidTokens, error } = formState;

    const errorCount = getErrorCount(invalidTokens);
    return createdTokens.length || invalidTokens.length
        ? {
              title: getResultsTitle({ createdTokens, invalidTokens, error }),
              resultsSummary: getResultsSummary({
                  createdTokens,
                  invalidTokens,
                  error,
              }),
              errorsExceedThreshold: errorCount >= ERROR_COUNT_THRESHOLD,
              errors: getErrors(invalidTokens),
              tokensWithErrorsCount: invalidTokens.length,
          }
        : {
              title: '',
          };
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//  import { useHistory } from 'react-router-dom';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import {
    LABEL_LOGIN,
    LABEL_REGISTER,
    LOGIN_SCREEN_HEADING,
    LOGIN_SCREEN_STATEMENT,
    LOGIN_SCREEN_STATEMENT_MORE,
} from './constants';
import { connect } from 'react-redux';
import { ConnectedMainLayout } from '../../layout/MainLayout';
import { useOktaAuth } from '@okta/okta-react';
import Button from '../../common/Button';
import { noop } from '../../common/utils';
import oktaConfig from '../../auth/oktaConfig';

const LoginScreen = (props) => {
    const { classes, onClickRegister } = props;

    const { authService } = useOktaAuth() || {};
    const [loadingMyAccess, setLoadingMyAccess] = useState(false);
    const signInWithRedirect = async () => {
        setLoadingMyAccess(true);
        await authService.redirect();
    };

    useEffect(() => {
        if (authService.isAuthenticated) {
            // Add tokens to storage
            const tokenManager = authService.getTokenManager();
            tokenManager.add('idToken', authService.getIdToken());
            tokenManager.add('accessToken', authService.getAccessToken());

            // Return to the original URL (if auth was initiated from a secure route), falls back to the origin
            const fromUri = authService.getFromUri();
            window.location.assign(fromUri);
        }
    }, [authService]);

    return (
        <Grid
            container
            wrap="nowrap"
            justify="center"
            alignItems="flex-start"
            className={classes.root}
        >
            <Grid item>
                <Grid container direction="column" className={classes.left}>
                    <Grid
                        item
                        component={Typography}
                        variant="h3"
                        className={classes.heading}
                    >
                        {LOGIN_SCREEN_HEADING}
                    </Grid>
                    <Grid
                        item
                        component={Typography}
                        className={classes.statement}
                    >
                        {LOGIN_SCREEN_STATEMENT}
                    </Grid>
                    <Grid
                        item
                        component={Typography}
                        className={classes.statement}
                        dangerouslySetInnerHTML={{
                            __html: LOGIN_SCREEN_STATEMENT_MORE,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.activateSection}
                >
                    <Grid item className={classes.registerButton}>
                        <Button
                            disabled={loadingMyAccess}
                            onClick={signInWithRedirect}
                        >
                            {LABEL_LOGIN}
                        </Button>
                    </Grid>

                    <Grid item className={classes.registerButton}>
                        <Button color="secondary" onClick={onClickRegister}>
                            {LABEL_REGISTER}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

LoginScreen.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,
    // Function to be called when the Register button is clicked.
    onClickRegister: PropTypes.func,
};

LoginScreen.defaultProps = {
    onClickRegister: noop,
};

const StyledLoginScreen = withStyles(styles)(LoginScreen);
export default StyledLoginScreen;

export const ConnectedLoginScreen = connect()((props) => {
    return (
        <ConnectedMainLayout>
            <StyledLoginScreen
                onClickRegister={() =>
                    window.location.assign(`${oktaConfig.registartionIri}?target=${oktaConfig.clientId}`)
                }
                {...props}
            />
        </ConnectedMainLayout>
    );
});

export default (theme) => ({
    '@media all and (-ms-high-contrast:none)': {
        // IE11 FIX OKTA 3RD PARTY LOGIN
        '#okta-sign-in': {
            width: 'auto',
        },
    },
    '#okta-sign-in a.js-forgot-password:focus': {
        outline: `1px solid ${theme.palette.darkGray}`,
    },
    '#okta-sign-in .infobox-error:before': {
        backgroundColor: theme.palette.error.main,
    },
    '#okta-sign-in .o-form-explain.o-form-input-error': {
        color: theme.palette.error.main,
        '&> span.icon:before': {
            color: theme.palette.error.main,
        },
    },
    '#root': {
        minHeight: '100%',

        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    html: {
        backgroundColor: theme.palette.lightGray,
    },
    body: {
        backgroundColor: theme.palette.lightGray,
    },
    a: {
        color: theme.palette.primary.main,
        '&:visited': {
            color: theme.palette.disabled,
        },
        '&:hover, &:active': {
            backgroundColor: theme.palette.lightGray,
            color: theme.palette.primaryForeground,
        },
    },
});

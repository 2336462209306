export default (theme) => ({
    root: {
        padding: '5rem 0',
    },
    noPaddingTop: {
        padding: '0 0 5rem',
    },
    errorBannerContainer: {
        margin: '2rem 0 2.5rem',
        width: '100%',
        maxWidth: 'calc(100vw - 30px)', // lovely IE compat
    },
    intro: {
        marginBottom: '1.5rem',
        maxWidth: '830px', // growth limit required for IE11 (absolute units)
    },
    radio: {
        color: theme.palette.primary.main,
    },
    separator: {
        border: `1px solid ${theme.palette.primary.main}`,
        height: 0,
        margin: 15,
        display: 'flex',
        flex: 1,
        flexGrow: 1,
        padding: '0 !important',
    },
    textArea: {
        marginBottom: 16,
    },
    separatorText: {
        fontSize: 22,
        marginTop: 30,
        marginBottom: 30,
    },
    helpText: {
        marginTop: 10,
        marginBottom: 20,
        fontStyle: 'italic',
        fontSize: 16,
        color: theme.palette.darkGray,
    },
    uploadFile: {
        display: 'none',
    },
    uploadLabel: {
        width: '100%',
        marginBottom: 20,
    },
    link: {
        cursor: 'pointer',
    },
    fileDownloadedAnnouncement: {
        position: 'absolute',
        color: theme.palette.white, // must be visible to screen reader, but use same color as background so not shown
    },
});

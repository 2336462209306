import {
    FETCH_USER_REQUESTED,
    FETCH_USER_SUCCEEDED,
    FETCH_USER_FAILED,
    REGISTER_USER_REQUESTED,
    REGISTER_USER_SUCCEEDED,
    REGISTER_USER_FAILED,
    FETCH_TA_REQUESTED,
    FETCH_TA_SUCCEEDED,
    FETCH_TA_FAILED,
} from './actions';

export const initialUserState = {
    userSearch: {
        email: null,
    },
    user: {
        firstName: null,
        middleName: null,
        lastName: null,
        email: null,
        oktaId: null,
        status: null,
        createdBy: null,
        modifiedBy: null,
    },
    testAuthority: {
        taId: null,
        name: null,
        status: null
    },
    fetchUserInProgress: false,
    registerUserInProgress: false,
    error: null,
};

export default (state = initialUserState, action = {}) => {
    const { type } = action;
    switch (type) {
        case FETCH_USER_REQUESTED: {
            const { userSearch } = action;
            return {
                ...state,
                fetchUserInProgress: true,
                error: null,
                userSearch,
            };
        }
        case FETCH_USER_SUCCEEDED: {
            const { user } = action;
            return {
                ...state,
                fetchUserInProgress: false,
                error: null,
                user,
            };
        }
        case FETCH_USER_FAILED: {
            const { error } = action;
            return {
                ...state,
                fetchUserInProgress: false,
                error,
            };
        }
        case REGISTER_USER_REQUESTED: {
            const { user } = action;
            return {
                ...state,
                registerUserInProgress: true,
                error: null,
                user,
            };
        }
        case REGISTER_USER_SUCCEEDED: {
            const { user } = action;
            return {
                ...state,
                registerUserInProgress: false,
                error: null,
                user,
            };
        }
        case REGISTER_USER_FAILED: {
            const { error } = action;
            return {
                ...state,
                registerUserInProgress: false,
                error,
            };
        }
        case FETCH_TA_REQUESTED: {
            const { userSearch } = action;
            return {
                ...state,
                fetchUserInProgress: true,
                error: null,
                userSearch,
            };
        }
        case FETCH_TA_SUCCEEDED: {
            const { testAuthority } = action;
            return {
                ...state,
                fetchUserInProgress: false,
                error: null,
                testAuthority,
            };
        }
        case FETCH_TA_FAILED: {
            const { error } = action;
            return {
                ...state,
                fetchUserInProgress: false,
                error,
            };
        }
        default: {
            return state;
        }
    }
};

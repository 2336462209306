export const TOKENS_FORM_TITLE = 'Submit new tokens';
export const TOKENS_FORM_SUBMIT_BUTTON_TEXT = 'Submit';

export const NAME_FOR_TOKEN_OPTIONS_FIELD = 'tokenOptions';
export const NAME_FOR_TOKENS_TEXT_FIELD = 'tokens';
export const NAME_FOR_TOKENS_UPLOAD_FIELD = 'uploadFile';

export const TOKENS_TEXT = 'text';
export const TOKENS_CSV = 'upload';

export const TEMPLATE_CSV_DATA =
    'ABCD12345678901\nABCD12345678901\nABCD12345678901\nABCD12345678901\nABCD12345678901\nABCD12345678901\nABCD12345678901\nABCD12345678901\nABCD12345678901\nABCD12345678901';

export const TOKENS_REGEX_NUMBERS = /^(?:[A-Z]{4})[0-9]+$/;
export const TOKENS_SEPARATOR = ',';
export const TOKENS_LENGTH_LIMIT = 25;
export const TOKENS_UPLOAD_LENGTH_LIMIT = 10000;
export const TOKEN_LENGTH = 15;

export const ACCEPTED_FILE_TYPES =
    '(.*\\/csv|.*\\/x-csv|.*\\/comma-separated-values|.*\\/x-comma-separated-values|application\\/vnd\\.ms-excel|text\\/plain)';
// only .csv so the file select dialog defaults to CSV. user can still change to All Files.
export const ACCEPTED_FILE_TYPE_EXTENSIONS = '.csv';

export const VALIDATION_ERROR_MESSAGE_TOKENS_LIMIT =
    'Only up to 25 tokens are supported.';
export const VALIDATION_ERROR_MESSAGE_INCORRECT_LENGTH =
    'Incorrect randomized numeric length';
export const VALIDATION_ERROR_MESSAGE_INCORRECT_TAID = 'Incorrect TAID';
export const VALIDATION_ERROR_MESSAGE_INCORRECT_NUMBERS =
    'Only numbers can follow TAID.';
export const VALIDATION_ERROR_MESSAGE_INVALID_FILE_TYPE = 'Invalid file type.';
export const VALIDATION_ERROR_MESSAGE_UPLOAD_LIMIT = 'Too many tokens.';

export const ERROR_COUNT_THRESHOLD = 100;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Popover, withStyles } from '@material-ui/core';
import styles from './styles';
import FaaLogo from './FaaLogo';
import Nav from '../../navigation/Nav';
import NavItemLink from '../../navigation/NavItemLink';
import { ROUTE_LOGIN, ROUTE_LOGOUT } from '../../routeConstants';
import noop from '../../common/utils';
import { faUser, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { VARIANT_TEXT } from '../../common/Button';
import { useHistory } from 'react-router-dom';
import { HELP_LINK_1_TITLE, HELP_LINK_2_TITLE } from './constants';

const Header = ({
    classes,
    faa,
    uas,
    loggedIn,
    name,
    taId,
    onLogout,
    registering,
}) => {
    const history = useHistory();
    const [helpAnchor, setHelpAnchor] = useState(null);

    const onClickHelp = (event) => {
        setHelpAnchor(event.currentTarget);
    };
    const onHelpClose = () => {
        setHelpAnchor(null);
    };

    const renderHelpIcon = () => (
        <Grid item className={classes.navItem}>
            <Grid
                item
                className={classes.help}
                onClick={onClickHelp}
                component={Button}
                variant={VARIANT_TEXT}
                tabIndex="0"
                disableRipple
            >
                <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
                &nbsp; Help
            </Grid>
        </Grid>
    );

    const renderFaaLogoWithTitle = () => (
        <Grid container alignItems="center" spacing={2}>
            <Grid item>
                <FaaLogo large={true} />
            </Grid>

            <Grid item component={Typography} className={classes.faa}>
                {faa}
            </Grid>

            <Grid item component={Typography} className={classes.uasTitle}>
                {uas}
            </Grid>
        </Grid>
    );

    const renderWelcomeName = () => (
        <Grid item>
            <Typography className={classes.name}>
                Welcome &nbsp;{name} &nbsp;|&nbsp; Identifier: {taId}
            </Typography>
        </Grid>
    );
    const renderNavLinks = () => {
        return loggedIn ? (
            <Grid container direction="column" justify="flex-end">
                <Grid item container className={classes.welcome}>
                    {renderWelcomeName()}
                </Grid>
                <Grid container item justify="flex-end">
                    <Nav>
                        <NavItemLink
                            to={ROUTE_LOGOUT}
                            onClick={onLogout}
                            component={Button}
                            variant={VARIANT_TEXT}
                            tabIndex="0"
                            rootClassName={classes.navItem}
                            disableRipple
                        >
                            <FontAwesomeIcon icon={faUser} size="lg" />
                            &nbsp; Sign Out
                        </NavItemLink>
                        {renderHelpIcon()}
                    </Nav>
                </Grid>
            </Grid>
        ) : registering ? (
            <Grid container item justify="flex-end">
                <Nav>
                    <NavItemLink
                        to={ROUTE_LOGIN}
                        onClick={() => history.push(ROUTE_LOGIN)}
                        component={Button}
                        variant={VARIANT_TEXT}
                        tabIndex="0"
                        rootClassName={classes.navItem}
                        disableRipple
                    >
                        <FontAwesomeIcon icon={faUser} size="lg" />
                        &nbsp; Sign In
                    </NavItemLink>
                    {renderHelpIcon()}
                </Nav>
            </Grid>
        ) : (
            renderHelpIcon()
        );
    };

    return (
        <Grid
            container
            direction="row-reverse"
            alignItems="center"
            justify="space-between"
        >
            <Grid item className={!loggedIn ? classes.links : {}}>
                {renderNavLinks()}
            </Grid>
            <Grid item className={classes.title}>
                {renderFaaLogoWithTitle()}
            </Grid>
            <Popover
                open={!!helpAnchor}
                anchorEl={helpAnchor}
                onClose={onHelpClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: -20,
                    horizontal: 'right',
                }}
            >
                <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                >
                    <Grid item className={classes.helpContent}>
                        <Typography className={classes.helpDirection}>
                            For IT Technical Help
                        </Typography>
                        <Typography>
                            Call: 1-844-FAA-MyIT (322-6948) <br />
                            Email:{' '}
                            <a
                                href="mailto:helpdesk@faa.gov"
                                target="_blank"
                                rel="noopener noreferrer"
                                title={HELP_LINK_1_TITLE}
                            >
                                helpdesk@faa.gov
                            </a>
                        </Typography>
                        <Grid item className={classes.trustTaHelp}>
                            <Typography className={classes.helpDirection}>
                                For TRUST Test Administration Procedures Help
                            </Typography>
                            <Typography>
                                Email:{' '}
                                <a
                                    href="mailto:9-AVS-RecFlyer-Test-Admin@faa.gov"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title={HELP_LINK_2_TITLE}
                                >
                                    9-AVS-RecFlyer-Test-Admin@faa.gov
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </Grid>
    );
};
Header.propTypes = {
    classes: PropTypes.object.isRequired,
    faa: PropTypes.string.isRequired,
    uas: PropTypes.string.isRequired,
    onLogout: PropTypes.func,
    name: PropTypes.string,
    taId: PropTypes.string,
    loggedIn: PropTypes.bool,
    registering: PropTypes.bool,
};

Header.defaultProps = {
    faa: 'FAA',
    uas: 'TRUST',
    onLogout: noop,
    name: 'TA',
    taId: '',
    loggedIn: false,
    showExtraBars: false,
    registering: false,
};

export default withStyles(styles)(Header);

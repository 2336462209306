const scaledButtonFont = (theme) => ({
    fontSize: 28,
    [theme.breakpoints.down('md')]: {
        fontSize: 24,
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: 20,
    },
});
export default (theme) => ({
    identity: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        padding: '1rem 0',
        fontSize: '1.25rem',

        '@media print': {
            padding: 5,
        },
        justifyContent: 'space-between',
        display: 'flex',
    },
    root: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&> *': {
            padding: 15,
        },
    },
    button: {
        color: theme.palette.white,
        padding: '5px 10px !important',
        display: 'flex',
        ...scaledButtonFont(theme),
        '&> span > svg': {
            marginRight: 10,
        },
    },
    links: {
        '&> *': {
            padding: '.5rem 2.5rem',
            [theme.breakpoints.down('md')]: {
                padding: '.5rem 1.5rem',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '.5rem .75rem',
            },
        },
        '&> :first-child': {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
            },
        },
    },
});

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';

import DotLogo from './DotLogo';

const Runner = ({ classes }) => {
    return (
        <Grid
            container
            direction="row"
            className={classes.root}
            alignItems="center"
            justify="flex-start"
            wrap="nowrap"
        >
            <Grid item className={classes.logo}>
                <DotLogo />
            </Grid>
            <Grid item>
                <Typography className={classes.text}>
                    United States Department of Transportation
                </Typography>
            </Grid>
        </Grid>
    );
};
Runner.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Runner);

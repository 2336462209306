import { createSelector } from 'reselect';

export const getAuthState = (state) => state.auth || {};

export const getUserName = createSelector(
    getAuthState,
    (authState) => authState.userInfo && authState.userInfo.name,
);

export const getUserEmail = createSelector(
    getAuthState,
    (authState) => authState.userInfo && authState.userInfo.email,
);

export const getOktaId = createSelector(
    getAuthState,
    (authState) => authState.userInfo && authState.userInfo.sub,
);

export const getUserTaId = createSelector(
    getAuthState,
    (authState) => authState.userInfo && authState.userInfo.given_name,
    // The `firstName` aka `given_name` field in Okta has the taId in it ;)
);

export const getAccessToken = createSelector(
    getAuthState,
    (authState) => authState.accessToken,
);

export const getError = createSelector(getAuthState, (auth) => {
    if (typeof auth.error === 'string') {
        return auth.error;
    } else if (auth && auth.error && auth.error.message) {
        return auth.error.message;
    } else {
        return null;
    }
});

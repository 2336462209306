import { createSelector } from 'reselect';

export const getState = (state) => state || {};
export const getDbUserState = (state) => state.db || {};
export const getUser = createSelector(getState, (userState) => {
    return userState.db && userState.db.user
        ? {
              firstName: userState.db.user.firstName,
              middleName: userState.db.user.middleName,
              lastName: userState.db.user.lastName,
              email: userState.db.user.email?.toLowerCase(),
              oktaId: userState.db.user.oktaId,
              createdBy: userState.db.user.createdBy,
              modifiedBy: userState.db.user.modifiedBy,
              status: userState.db.user.status,
          }
        : {
              firstName: userState.auth.userInfo.FirstName,
              middleName: userState.auth.userInfo.MiddleName,
              lastName: userState.auth.userInfo.LastName,
              email: userState.auth.userInfo.email?.toLowerCase(),
              oktaId: userState.auth.userInfo.sub,
              createdBy: userState.auth.userInfo.name,
              modifiedBy: userState.auth.userInfo.name,
              status: '',
          };
});

export const getUserEmail = createSelector(
    getDbUserState,
    (userState) => userState.user && userState.user.email,
);

export const getUserOktaId = createSelector(
    getDbUserState,
    (userState) => userState.user && userState.user.oktaId,
);

export const getUserTaId = createSelector(
    getDbUserState,
    (userState) => userState.testAuthority && userState.testAuthority.taId,
);

export const getUserName = createSelector(
    getDbUserState,
    (userState) => userState.testAuthority && userState.testAuthority.name,
);

export const getUserTaStatus = createSelector(
    getDbUserState,
    (userState) => userState.testAuthority && userState.testAuthority.status,
);

export const isFetchUserInProgress = createSelector(
    getDbUserState,
    (userState) => userState.fetchUserInProgress,
);

export const isRegisterUserInProgress = createSelector(
    getDbUserState,
    (userState) => userState.registerUserInProgress,
);

export const getError = createSelector(getDbUserState, (userState) => {
    if (typeof userState.error === 'string') {
        return userState.error;
    } else if (userState && userState.error && userState.error.message) {
        return userState.error.message;
    } else {
        return null;
    }
});

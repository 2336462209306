import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { ROUTE_HOME } from '../../routeConstants';
import styles from './styles';
import Button, { VARIANT_TEXT } from '../../common/Button';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const SubHeader = ({ classes }) => {
    const history = useHistory();
    return (
        <Grid container direction="row" xs={12} className={classes.root}>
            <Grid
                container
                item
                alignItems="center"
                justify="flex-start"
                className={classes.links}
            >
                <Grid item>
                    <Button
                        variant={VARIANT_TEXT}
                        className={classes.button}
                        to={ROUTE_HOME}
                        onClick={() => {
                            history.push(ROUTE_HOME);
                        }}
                    >
                        <FontAwesomeIcon icon={faHome} />
                        &nbsp;Home
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
SubHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SubHeader);

const config = {
    clientId: null,
    issuer: null,
    redirectUri: null,
    logoutUri: null,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    registartionIri:null
};

export default config;

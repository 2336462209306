import {
    STORE_TOKENS,
    SUBMIT_TOKENS_FAILED,
    SUBMIT_TOKENS_REQUESTED,
    SUBMIT_TOKENS_SUCCEEDED
} from "./actions";

const initialState = {
    validTokens: [
        // ''
    ],
    invalidTokens: [
        // { token: '', messages: [] }
    ],
    submitInProgress: false,
    error: null,
    createdTokens: [
        // { taId: '', tokenId: '', ...more  }
    ],
};

export default (state = initialState, action = {}) => {
    const { type } = action;
    switch (type) {
        case STORE_TOKENS: {
            const { validTokens, invalidTokens } = action;
            return {
                ...state,
                validTokens,
                invalidTokens,
            };
        }
        case SUBMIT_TOKENS_REQUESTED: {
            return {
                ...state,
                submitInProgress: true,
                createdTokens: [],
                error: null,
            };
        }
        case SUBMIT_TOKENS_SUCCEEDED: {
            const {
                createdTokens,
                invalidTokens,
            } = action;
            return {
                ...state,
                submitInProgress: false,
                createdTokens,
                invalidTokens: [
                    ...state.invalidTokens,
                    ...invalidTokens,
                ]
            };
        }
        case SUBMIT_TOKENS_FAILED: {
            const { error } = action;
            return {
                ...state,
                submitInProgress: false,
                createdTokens: [],
                error,
            };
        }
        default:
            return state;
    }
};

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
    Grid,
    Link,
    Radio,
    RadioGroup,
    Typography,
    withStyles,
} from '@material-ui/core';
import Form from '../../forms/Form';
import { formPropTypes } from '../../forms/Form/propTypes';
import styles from './styles';
import {
    NAME_FOR_TOKEN_OPTIONS_FIELD,
    NAME_FOR_TOKENS_TEXT_FIELD,
    NAME_FOR_TOKENS_UPLOAD_FIELD,
    TOKEN_LENGTH,
    TOKENS_CSV,
    TOKENS_FORM_SUBMIT_BUTTON_TEXT,
    TOKENS_FORM_TITLE,
    TOKENS_LENGTH_LIMIT,
    TOKENS_UPLOAD_LENGTH_LIMIT,
    TOKENS_REGEX_NUMBERS,
    VALIDATION_ERROR_MESSAGE_TOKENS_LIMIT,
    TOKENS_SEPARATOR,
    TOKENS_TEXT,
    ACCEPTED_FILE_TYPES,
    ACCEPTED_FILE_TYPE_EXTENSIONS,
    VALIDATION_ERROR_MESSAGE_INCORRECT_TAID,
    VALIDATION_ERROR_MESSAGE_INCORRECT_NUMBERS,
    VALIDATION_ERROR_MESSAGE_INCORRECT_LENGTH,
    VALIDATION_ERROR_MESSAGE_INVALID_FILE_TYPE,
    VALIDATION_ERROR_MESSAGE_UPLOAD_LIMIT,
    TEMPLATE_CSV_DATA,
} from './constants';
import TextInput from '../../common/TextInput';
import Button, { VARIANT_OUTLINED } from '../../common/Button';
import { connect } from 'react-redux';
import { ConnectedMainLayout } from '../../layout/MainLayout';
import { storeTokens, submitTokensRequested } from './actions';
import { isSubmitInProgress } from './selectors';
import { getUserTaId } from '../../db/selectors';
import AlertBanner from '../../common/AlertBanner';
import { VARIANT_DANGER } from '../../common/AlertBanner/constants';
import clsx from 'clsx';

Yup.addMethod(
    Yup.string,
    'tokens',
    function ({
        submitType,
        message,
        taId,
        storeTokens,
        lengthLimit,
        showFileErrorBanner,
        hideFileErrorBanner,
    }) {
        return this.test('validTokens', message, (value = '') => {
            const values = value
                .toUpperCase()
                .replace(/(\r\n|\n|\r)/gm, ',')
                .replace(/\s+/g, '')
                .split(TOKENS_SEPARATOR)
                .filter(Boolean);
            const validLength = values.length <= lengthLimit;
            if (validLength) {
                const tokens = values.reduce(
                    (accumulator, token) => {
                        if (token) {
                            const trimmedToken = token.trim();
                            const validLength =
                                trimmedToken.length === TOKEN_LENGTH;
                            const validTaId = trimmedToken.startsWith(taId);
                            const validNumbers =
                                trimmedToken.match(TOKENS_REGEX_NUMBERS);
                            const validToken =
                                validLength && validTaId && validNumbers;
                            if (!validToken) {
                                const messages = [];
                                if (!validTaId) {
                                    messages.push(
                                        VALIDATION_ERROR_MESSAGE_INCORRECT_TAID,
                                    );
                                } else {
                                    if (!validLength) {
                                        messages.push(
                                            VALIDATION_ERROR_MESSAGE_INCORRECT_LENGTH,
                                        );
                                    }
                                    if (!validNumbers) {
                                        messages.push(
                                            VALIDATION_ERROR_MESSAGE_INCORRECT_NUMBERS,
                                        );
                                    }
                                }
                                accumulator.invalidTokens.push({
                                    token: trimmedToken,
                                    messages,
                                });
                            } else {
                                accumulator.validTokens.push(token);
                            }
                        }
                        return accumulator;
                    },
                    { validTokens: [], invalidTokens: [] },
                );
                storeTokens(tokens);
                return true;
            } else if (submitType === NAME_FOR_TOKENS_UPLOAD_FIELD) {
                showFileErrorBanner(VALIDATION_ERROR_MESSAGE_UPLOAD_LIMIT);
                return false;
            } else {
                hideFileErrorBanner();
                return false;
            }
        });
    },
);

export function TokensForm(props) {
    const { classes, taId, storeTokens, ...restProps } = props;
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [errorBannerMessage, setErrorBannerMessage] = useState({
        textLine1: '',
        textLine2: <Grid item />,
    });
    const [downloaded, setDownloaded] = useState(false);
    const resetDownloadedFlag = useCallback(() => {
        setTimeout(setDownloaded, 1000, false);
        return true;
    }, [setDownloaded]);

    const hideFileErrorBanner = () => setShowErrorBanner(false);
    const showFileErrorBanner = (info) => {
        setErrorBannerMessage({
            textLine1: `There was a problem with your file: ${info}`,
            textLine2: (
                <Grid item container direction="column">
                    <Grid item component={Typography}>
                        Common errors include uploading a file containing more
                        than 10,000 tokens or it is the incorrect file type.
                    </Grid>
                    <Grid item component={Typography}>
                        Please confirm the file has fewer than 10,000 tokens and
                        is in the correct format.
                    </Grid>
                </Grid>
            ),
        });
        setShowErrorBanner(true);
        try {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (e) {
            window.scrollTo(0, 0);
        }
        setTimeout(() => {
            document.getElementById('alert-banner').focus();
        }, 300);
    };

    const validationSchema = {
        [NAME_FOR_TOKENS_TEXT_FIELD]: Yup.string()
            .when(NAME_FOR_TOKEN_OPTIONS_FIELD, {
                is: (value) => value === TOKENS_TEXT,
                then: Yup.string()
                    .tokens({
                        submitType: NAME_FOR_TOKENS_TEXT_FIELD,
                        message: VALIDATION_ERROR_MESSAGE_TOKENS_LIMIT,
                        taId,
                        storeTokens,
                        lengthLimit: TOKENS_LENGTH_LIMIT,
                        hideFileErrorBanner,
                    })
                    .ensure()
                    .required(),
                otherwise: Yup.string(),
            })
            .label('Tokens'),
        [NAME_FOR_TOKENS_UPLOAD_FIELD]: Yup.string()
            .when(NAME_FOR_TOKEN_OPTIONS_FIELD, {
                is: (value) => value !== TOKENS_TEXT,
                then: Yup.string()
                    .tokens({
                        submitType: NAME_FOR_TOKENS_UPLOAD_FIELD,
                        message: ' ', // Yup will pass validation if the message is empty or null -_-
                        taId,
                        storeTokens,
                        lengthLimit: TOKENS_UPLOAD_LENGTH_LIMIT,
                        showFileErrorBanner,
                        hideFileErrorBanner,
                    })
                    .ensure()
                    .required(),
                otherwise: Yup.string(),
            })
            .label('Upload'),
    };

    const handleCsvTemplateDownload = useCallback(() => {
        const csvData = new Blob([TEMPLATE_CSV_DATA], {
            type: 'text/csv;charset=utf-8;',
        });
        const exportFilename = 'Example Token Upload Format.csv';
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(csvData, exportFilename);
        } else {
            // In FF link must be added to DOM to be clicked
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(csvData);
            link.setAttribute('download', exportFilename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        setDownloaded(true);
    }, [setDownloaded]);

    const handleCsvTemplateKeyboard = useCallback(
        (e) => {
            if (e.key === 'Enter') {
                handleCsvTemplateDownload();
            }
        },
        [handleCsvTemplateDownload],
    );

    const fileInputRef = React.createRef();
    const [fileName, setFileName] = useState(null);
    const renderTokensFormFields = useCallback(
        () => [
            {
                name: 'intro',
                render: () => (
                    <Typography className={classes.intro} tabIndex={0}>
                        When submitting new tokens:
                        <ul>
                            <li>
                                Tokens must be uploaded by the end of the
                                calendar month in which the test was taken and
                                certificate issued.
                            </li>
                            <li>
                                Tokens must start with the 4 letter TA
                                identifier you were issued, followed by 11
                                random numeric characters you create. For
                                example: ABCD12345678912.
                            </li>
                            <li>
                                Enter up to 25 tokens in the box below separated
                                by commas.
                            </li>
                            <li>
                                Submit up to 10,000 tokens using a CSV file.
                            </li>
                        </ul>
                    </Typography>
                ),
            },
            {
                name: NAME_FOR_TOKEN_OPTIONS_FIELD,
                render: (
                    fieldProps,
                    { setFieldValue, errors, validateForm },
                ) => {
                    const { value } = fieldProps;
                    const handleFile = (e) => {
                        let content = e.target.result;
                        if (content.startsWith('tokens\n')) {
                            content = content
                                .substring(content.indexOf('\n') + 1)
                                .trim();
                        }
                        setFieldValue(NAME_FOR_TOKENS_UPLOAD_FIELD, content);
                        setTimeout(validateForm);
                    };
                    const handleChangeFile = (file) => {
                        if (
                            file &&
                            (!file.type ||
                                !file.type.match(ACCEPTED_FILE_TYPES))
                        ) {
                            setFileName(null);
                            setFieldValue(NAME_FOR_TOKENS_UPLOAD_FIELD, '');
                            showFileErrorBanner(
                                VALIDATION_ERROR_MESSAGE_INVALID_FILE_TYPE,
                            );
                            return false;
                        } else {
                            setShowErrorBanner(false);
                        }
                        const fileData = new FileReader();
                        fileData.onloadend = handleFile;
                        fileData.readAsText(file);
                        setFileName(file.name);
                    };
                    return (
                        <RadioGroup required {...fieldProps}>
                            <Grid
                                container
                                justify="center"
                                alignItems="flex-start"
                                wrap="nowrap"
                            >
                                <Grid item>
                                    {' '}
                                    <Radio
                                        inputProps={{
                                            'aria-label':
                                                'Select to enter tokens',
                                            'aria-describedby':
                                                'tokenInputHelp',
                                        }}
                                        color="primary"
                                        classes={{ root: classes.radio }}
                                        value={TOKENS_TEXT}
                                    />
                                </Grid>
                                <Grid item container>
                                    <Grid
                                        item
                                        id="tokenInputHelp"
                                        component={Typography}
                                        className={classes.helpText}
                                    >
                                        Add one or more tokens separated by a
                                        comma
                                    </Grid>
                                    <Grid
                                        item
                                        component={TextInput}
                                        className={classes.textArea}
                                        inputProps={{
                                            'aria-label':
                                                'Enter up to 25 tokens.',
                                        }}
                                        FormHelperTextProps={{
                                            role: 'alert',
                                        }}
                                        multiline
                                        rows={10}
                                        rowsMax={10}
                                        disabled={value !== TOKENS_TEXT}
                                        moreOpaqueDisabledInput
                                        placeholder={`${taId}12345678912`}
                                        highContrastPlaceholder
                                        onChange={(event) =>
                                            setFieldValue(
                                                NAME_FOR_TOKENS_TEXT_FIELD,
                                                event.target.value,
                                            )
                                        }
                                        error={
                                            errors &&
                                            errors[NAME_FOR_TOKENS_TEXT_FIELD]
                                                ? errors[
                                                      NAME_FOR_TOKENS_TEXT_FIELD
                                                  ]
                                                : null
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                spacing={4}
                            >
                                <Grid item className={classes.separator} />
                                <Grid
                                    item
                                    component={Typography}
                                    align="center"
                                    color="primary"
                                    className={classes.separatorText}
                                >
                                    Or
                                </Grid>
                                <Grid item className={classes.separator} />
                            </Grid>
                            <Grid
                                container
                                justify="center"
                                alignItems="flex-start"
                                wrap="nowrap"
                            >
                                <Grid item>
                                    <Radio
                                        inputProps={{
                                            'aria-label':
                                                'Select to upload a CSV file',
                                            'aria-describedby': 'tokenFileHelp',
                                        }}
                                        color="primary"
                                        classes={{ root: classes.radio }}
                                        value={TOKENS_CSV}
                                    />
                                </Grid>
                                <Grid item container>
                                    <Grid
                                        item
                                        id="tokenFileHelp"
                                        component={Typography}
                                        className={classes.helpText}
                                    >
                                        Upload your CSV file:&nbsp;
                                        <Link
                                            className={classes.link}
                                            download
                                            onClick={handleCsvTemplateDownload}
                                            onKeyPress={
                                                handleCsvTemplateKeyboard
                                            }
                                            tabIndex={
                                                value === TOKENS_CSV ? 0 : -1
                                            }
                                        >
                                            Download CSV Template
                                        </Link>
                                        <span
                                            className={
                                                classes.fileDownloadedAnnouncement
                                            }
                                            aria-live="assertive"
                                        >
                                            {downloaded &&
                                                resetDownloadedFlag() && (
                                                    <>CSV File Downloaded</>
                                                )}
                                        </span>
                                    </Grid>
                                    <label
                                        className={classes.uploadLabel}
                                        htmlFor={NAME_FOR_TOKENS_UPLOAD_FIELD}
                                        aria-describedby={`${NAME_FOR_TOKENS_UPLOAD_FIELD}-helper-text`}
                                    >
                                        <input
                                            id={NAME_FOR_TOKENS_UPLOAD_FIELD}
                                            className={classes.uploadFile}
                                            name={NAME_FOR_TOKENS_UPLOAD_FIELD}
                                            type="file"
                                            accept={
                                                ACCEPTED_FILE_TYPE_EXTENSIONS
                                            }
                                            disabled={value !== TOKENS_CSV}
                                            onChange={(event) => {
                                                handleChangeFile(
                                                    event.currentTarget
                                                        .files[0],
                                                );
                                            }}
                                            ref={fileInputRef}
                                        />
                                        <Button
                                            color="primary"
                                            variant={VARIANT_OUTLINED}
                                            fullWidth
                                            onClick={() => {
                                                fileInputRef.current.click();
                                            }}
                                            disabled={value !== TOKENS_CSV}
                                        >
                                            {fileName || 'Upload CSV'}
                                        </Button>
                                        {errors &&
                                            errors[
                                                NAME_FOR_TOKENS_UPLOAD_FIELD
                                            ] && (
                                                <Grid
                                                    container
                                                    item
                                                    component={Typography}
                                                    color="error"
                                                    id={`${NAME_FOR_TOKENS_UPLOAD_FIELD}-helper-text`}
                                                    role="alert"
                                                >
                                                    {
                                                        errors[
                                                            NAME_FOR_TOKENS_UPLOAD_FIELD
                                                        ]
                                                    }
                                                </Grid>
                                            )}
                                    </label>
                                    {errors[NAME_FOR_TOKEN_OPTIONS_FIELD] && (
                                        <Grid
                                            container
                                            item
                                            component={Typography}
                                            color="error"
                                        >
                                            {
                                                errors[
                                                    NAME_FOR_TOKEN_OPTIONS_FIELD
                                                ]
                                            }
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    );
                },
            },
        ],
        [
            classes,
            taId,
            fileName,
            fileInputRef,
            handleCsvTemplateDownload,
            handleCsvTemplateKeyboard,
            downloaded,
            resetDownloadedFlag,
        ],
    );
    return (
        <Grid
            container
            className={clsx([
                classes.root,
                showErrorBanner ? classes.noPaddingTop : {},
            ])}
            justify="center"
            alignContent="center"
            alignItems="center"
        >
            {showErrorBanner && (
                <Grid item className={classes.errorBannerContainer}>
                    <AlertBanner
                        id="alert-banner"
                        tabIndex={0}
                        variant={VARIANT_DANGER}
                        title="Error"
                        {...errorBannerMessage}
                        closeButton={true}
                        CloseButtonProps={{
                            'aria-label': 'Close Error',
                            onClick: () => {
                                setShowErrorBanner(false);
                            },
                        }}
                    />
                </Grid>
            )}
            <Grid item>
                <Form
                    usePaper
                    fields={renderTokensFormFields()}
                    FormProps={{
                        validationSchema: Yup.object().shape(validationSchema),
                        validateOnChange: false,
                    }}
                    {...restProps}
                />
            </Grid>
        </Grid>
    );
}

TokensForm.propTypes = {
    // From withStyles we expect to get classes.
    classes: PropTypes.object.isRequired,

    // All form prop types.
    ...formPropTypes,

    taId: PropTypes.string.isRequired,

    storeTokens: PropTypes.func.isRequired,
};

TokensForm.defaultProps = {
    title: TOKENS_FORM_TITLE,
    submitButtonText: TOKENS_FORM_SUBMIT_BUTTON_TEXT,
    initFormState: {
        [NAME_FOR_TOKEN_OPTIONS_FIELD]: TOKENS_TEXT,
    },
};

export const StyledTokensForm = withStyles(styles)(TokensForm);

const mapStateToProps = (state) => ({
    taId: getUserTaId(state),
    SubmitButtonProps: {
        loading: isSubmitInProgress(state),
        disabled: isSubmitInProgress(state),
    },
});

const mapDispatchToProps = (dispatch) => ({
    storeTokens: (tokens) => dispatch(storeTokens(tokens)),
    onSubmit: (tokens) => dispatch(submitTokensRequested(tokens)),
});

export const ConnectedTokensForm = connect(
    mapStateToProps,
    mapDispatchToProps,
)((props) => (
    <ConnectedMainLayout>
        <StyledTokensForm {...props} />
    </ConnectedMainLayout>
));

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import initReducer from '../init/reducer';
import tokensForm from '../screens/TokensForm/reducer';
import auth from '../auth/reducer';
import db from '../db/reducer';

export default function createRootReducer(history) {
    return combineReducers({
        router: connectRouter(history),
        init: initReducer,
        tokensForm,
        auth,
        db,
    });
}

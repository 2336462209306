export const REGISTER_SCREEN_HEADING =
    'The Recreational UAS Safety Test Registration';
export const REGISTER_AUTHORIZED_STATEMENT =
    'Our system indicates you need to register with UAS TRUST. Please click on "Register to UAS Trust" button.';
export const REGISTER_UNAUTHORIZED_STATEMENT =
    "Our system indicates that you're NOT authorized to register with UAS TRUST.";
export const REGISTER_REQUEST_STATEMENT =
    'Our system indicates you need to register with UAS TRUST. Please click on "Register to UAS Trust" button.';
export const HREF_FAA_CUST_SERV_EMAIL =
    '9-AVS-RecFlyer-Test-Admin@faa.gov';
export const REGISTER_PENDING_STATEMENT =
    `Your registration request is pending administrator review. Please signout now and contact us at <a href="mailto:${HREF_FAA_CUST_SERV_EMAIL}">9-AVS-RecFlyer-Test-Admin@faa.gov</a> to complete your registration.`;
export const HREF_OPERATOR_RULES_AND_HOW_TO_BECOME_TA =
    'https://www.faa.gov/go/uastrust';
export const REGISTER_SCREEN_STATEMENT_MORE = `Test Administrator Operator Rules and information about how to become an FAA Approved Test Administrator are available on <a href="${HREF_OPERATOR_RULES_AND_HOW_TO_BECOME_TA}" target="_blank" rel="noopener noreferrer">FAA.gov</a>.`;

export const OMB_CONTROL_NUMBER = 'OMB Control No. 2120-0794 ';
export const EXPIRATION_DATE = ' Expiration Date 2/29/2024';
export const SPACER = ' | ';

export const PRIVACY_ACT_HEADING =
    'PRIVACY ACT STATEMENT: This statement is provided pursuant to the Privacy Act of 1974, 5 United States Code (U.S.C.) § 552a.';
export const PRIVACY_ACT_TEXT =
    'You are accessing a United States (U.S.) Government information system which includes (1) this site and (2) the computers and networks connected to this site. The information system is provided for U.S. Government-authorized use only. You may find information about how the Federal Aviation Administration (FAA) will handle information that you provide through this Site in our Privacy Policy and our Privacy Act Notice. Users have no expectation of privacy while using the site. For any lawful Government purposes, such as identifying wrongful attempts to upload or change information in the system or otherwise cause damage to the web service, the Government may monitor and intercept information transiting or stored on the system. Use of this system constitutes consent to such monitoring or interception. Unauthorized or improper use of this information system may result in civil and criminal penalties.';

export const LABEL_LOGIN = 'Register to UAS Trust';
export const LABEL_GOBACK = 'Back to Login';
export const LABEL_TITLE = 'Log in';
export const LABEL_EMAIL = 'Email Address';
export const LABEL_HELP = 'Need help logging in?';
export const LABEL_REGISTER = 'Register Account';

export const LOGIN_SCREEN_HEADING =
    'The Recreational UAS Safety Test Token Database';
export const LOGIN_SCREEN_STATEMENT =
    'This portal provides FAA Approved Test Administrators of The Recreational UAS Safety Test (TRUST) the means to securely upload token information to the FAA.';
export const HREF_OPERATOR_RULES_AND_HOW_TO_BECOME_TA =
    'https://www.faa.gov/go/uastrust';
export const LOGIN_SCREEN_STATEMENT_MORE = `Test Administrator Operator Rules and information about how to become an FAA Approved Test Administrator are available on <a href="${HREF_OPERATOR_RULES_AND_HOW_TO_BECOME_TA}" target="_blank" rel="noopener noreferrer">FAA.gov</a>.`;

export const OMB_CONTROL_NUMBER = 'OMB Control No. 2120-0794 ';
export const EXPIRATION_DATE = ' Expiration Date 2/29/2024';
export const SPACER = ' | ';

export const PRIVACY_ACT_HEADING =
    'PRIVACY ACT STATEMENT: This statement is provided pursuant to the Privacy Act of 1974, 5 United States Code (U.S.C.) § 552a.';
export const PRIVACY_ACT_TEXT =
    'You are accessing a United States (U.S.) Government information system which includes (1) this site and (2) the computers and networks connected to this site. The information system is provided for U.S. Government-authorized use only. You may find information about how the Federal Aviation Administration (FAA) will handle information that you provide through this Site in our Privacy Policy and our Privacy Act Notice. Users have no expectation of privacy while using the site. For any lawful Government purposes, such as identifying wrongful attempts to upload or change information in the system or otherwise cause damage to the web service, the Government may monitor and intercept information transiting or stored on the system. Use of this system constitutes consent to such monitoring or interception. Unauthorized or improper use of this information system may result in civil and criminal penalties.';

export const LABEL_LOGIN = 'Login';
export const LABEL_GOBACK = 'Back to Login';
export const LABEL_TITLE = 'Log in';
export const LABEL_EMAIL = 'Email Address';
export const LABEL_HELP = 'Need help logging in?';
export const LABEL_REGISTER = 'Register Account';
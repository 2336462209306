import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import faaLogo from './faa-logo.png';
import faaLogo2x from './faa-logo-2x.png';
import clsx from 'clsx';

const styles = () => ({
    root: {
        paddingRight: '.5rem',
    },
    small: {
        height: 65,
        width: 73,
    },
    large: {
        height: 95,
        width: 103,
    },
});

const FaaLogo = ({ classes, large }) => (
    <img
        src={large ? faaLogo2x : faaLogo}
        alt="Federal Aviation Administration"
        className={clsx(classes.root, large ? classes.large : classes.small)}
    />
);

FaaLogo.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,
    large: PropTypes.bool,
};

FaaLogo.defaultProps = {
    large: false,
};

export default withStyles(styles)(FaaLogo);

export default (theme) => ({
    root: {
        padding: '1.5rem 5%',
        backgroundColor: theme.palette.white,

        [theme.breakpoints.down('md')]: {},
    },
    text: {
        fontSize: 14,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
    firstRightLink: {
        paddingTop: '0 !important',
        marginTop: '-8px',
    },
});

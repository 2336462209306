export default (theme) => ({
    root: {
        '& > p': {
            color: theme.palette.white,
        },
        backgroundColor: theme.palette.runnerBlue,
        fontSize: '1.25rem',
    },
    logo: {
        color: theme.palette.white,
        fontSize: 16,
        fontWeight: 700,
        minWidth: 32,

        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    text: {
        color: theme.palette.white,
        marginLeft: 10,
    },
});

import {
    STORE_AUTH_STATE,
} from './actions';

export const initialAuthState = {
    idToken: null,
    accessToken: null,
    userInfo: {
        name: '',
        given_name: '',
    },
    error: null,
};

export default (state = initialAuthState, action = {}) => {
    const { type, authState } = action;
    switch (type) {
        case STORE_AUTH_STATE: {
            return {
                ...state,
                ...authState,
            };
        }
        default: {
            return state;
        }
    }
};

import { VARIANT_DANGER, VARIANT_WARNING } from './constants';

export default (theme) => {
    const root = {
        borderWidth: 0,
        borderLeftWidth: 16,
        borderStyle: 'solid',
        position: 'relative',
        margin: '1rem 0',
        '&> .MuiCardContent-root': {
            padding: '2rem',
        },
    };

    return {
        [`root${VARIANT_WARNING}`]: {
            ...root,
            backgroundColor: theme.palette.warning.light,
            borderColor: theme.palette.warning.main,
        },
        [`root${VARIANT_DANGER}`]: {
            ...root,
            backgroundColor: theme.palette.error.light,
            borderColor: theme.palette.error.main,
        },
        icon: {
            paddingRight: '8px !important',
            '&> svg': {
                [theme.breakpoints.down('md')]: {
                    fontSize: 36,
                },
            },
        },
        title: {
            fontSize: 36,
            fontWeight: 900,
            [theme.breakpoints.down('sm')]: {
                fontSize: 28,
            },
        },
        cardContent: {
            [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap',
            },
            '&> .MuiGrid-item': {
                [theme.breakpoints.down('md')]: {
                    padding: 16,
                },
            },
        },
        textContainer: {
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                flexBasis: '100%',
            },
        },
        actionContainer: {
            marginLeft: 'auto',
            [theme.breakpoints.down('sm')]: {
                margin: '2rem auto 0',
                maxWidth: '100%',
                justifyContent: 'center',
            },
        },
        actionContainerBlock: {
            margin: '2rem auto 0',
            justifyContent: 'flex-start',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                justifyContent: 'center',
            },
            '&> .MuiGrid-item:first-child': {
                marginRight: '2rem',
                [theme.breakpoints.down('sm')]: {
                    marginRight: 0,
                    marginBottom: '1rem',
                },
            },
        },
        button: {
            minWidth: 420,
        },
        buttonLabel: {
            letterSpacing: 3.2,
            fontWeight: 700,
        },
        closeButton: {
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            padding: '10px !important',
            '&> button': {
                padding: 0,
            },
        },
    };
};

import droneImage from './drone.png';
import backgroundImage from './login-bg.png';

export default (theme) => {
    const text = {
        color: theme.palette.white,
        maxWidth: '100vw',
    };
    const statement = {
        ...text,
        fontSize: 18,
        fontWeight: 300,
        marginBottom: 20,

        '& a': {
            color: theme.palette.white,
            fontWeight: 700,
        },
        '& a:hover': {
            backgroundColor: 'transparent',
        },

        [theme.breakpoints.down('md')]: {
            marginLeft: 20,
            fontSize: 14,
        },
    };
    return {
        root: {
            height: '100%',
            backgroundImage: `url(${droneImage}), url(${backgroundImage})`,
            backgroundSize: 'auto 20%, cover',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundPosition: 'top 4% left, center',
            flexDirection: 'column',
            [theme.breakpoints.down('md')]: {
                backgroundImage: `url(${droneImage}), url(${backgroundImage})`,
                backgroundPosition: 'top 1% left, center',
                backgroundSize: 'auto 18%, cover',
                backgroundRepeat: 'no-repeat, no-repeat',
            },
            [theme.breakpoints.up('md')]: {
                backgroundImage: `url(${droneImage}), url(${backgroundImage})`,
                backgroundPosition: 'top 1% left, center',
                backgroundSize: 'auto 200px, cover',
                backgroundRepeat: 'no-repeat, no-repeat',
            },
            [theme.breakpoints.up('lg')]: {
                backgroundPosition: 'top 4% left, center',
                backgroundSize: '40% auto, cover',
                flexDirection: 'row',
            },
            [theme.breakpoints.up('xl')]: {
                backgroundPosition: 'top 4% left, center',
                backgroundSize: '40% auto, cover',
            },
            paddingTop: '50px',
            paddingBottom: '10px',
        },
        left: {
            marginTop: 325,
            marginLeft: 0,
            marginRight: 150,
            minWidth: 300,
            maxWidth: 644,

            [theme.breakpoints.down('lg')]: {
                marginTop: 350,
                marginLeft: 0,
                marginRight: 0,
            },

            [theme.breakpoints.down('md')]: {
                marginTop: 405,
                marginLeft: 0,
                marginRight: 0,
            },
            [theme.breakpoints.down('sm')]: {
                margin: 'auto',
                marginTop: 425,
            },
        },
        text,
        heading: {
            ...text,
            fontWeight: 900,
            marginBottom: 20,
            fontSize: 54,

            [theme.breakpoints.down('md')]: {
                marginTop: 25,
                marginLeft: 20,
                fontSize: 32,
            },
        },
        statement,
        statementLink: {
            ...statement,
            fontWeight: 'bold',
        },
        link: {
            ...text,
            fontSize: 20,
            fontWeight: 700,

            [theme.breakpoints.down('md')]: {
                marginLeft: 20,
                fontSize: 16,
            },
        },
        linkItem: {
            [theme.breakpoints.up('lg')]: {
                marginBottom: 150,
            },
        },
        form: {
            maxWidth: 555,
            padding: `0px !important`,
            marginTop: 60,
            marginBottom: 80,

            [theme.breakpoints.down('md')]: {
                maxWidth: '80vw',
            },

            '& #okta-sign-in .auth-content .help-links li a.js-help-link': {
                display: 'none',
            },
        },
        activateSection: {
            backgroundColor: theme.palette.white,
            padding: 40,
            paddingTop: 20,
            paddingBottom: 20,

            [theme.breakpoints.down('md')]: {
                padding: 5,
                paddingTop: 10,
                paddingBottom: 10,
                justifyContent: 'center',
                flexDirection: 'column',
            },
        },
        activateLeadText: {
            fontSize: 20,
            maxWidth: 400,
            padding: '20px 10px',
        },
        activateButton: {
            padding: 20,
            paddingRight: 0,
        },
        registerButton: {
            padding: 20,
            paddingRight: 0,
        }
    };
};

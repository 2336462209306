import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import { connect } from 'react-redux';
import { ConnectedMainLayout } from '../../layout/MainLayout';
import {
    getError,
    getUser,
    isRegisterUserInProgress,
} from '../../db/selectors';
import {
    registerUserRequested,
    fetchUserRequested,
    fetchTARequested,
} from '../../db/actions';
import {
    REGISTER_SCREEN_HEADING,
    REGISTER_REQUEST_STATEMENT,
    REGISTER_UNAUTHORIZED_STATEMENT,
    LABEL_REGISTER,
    REGISTER_SCREEN_STATEMENT_MORE,
    REGISTER_PENDING_STATEMENT,
} from './constants';
import Button from '../../common/Button';
import { noop } from '../../common/utils';
import LoadingScreen from '../../common/LoadingScreen';

const RegisterScreen = (props) => {
    const {
        classes,
        onClickRegister,
        onLoadFetchUser,
        onLoadFetchTA,
        user,
        auth,
        SubmitButtonProps,
        loading,
    } = props;

    useEffect(() => {
        if (auth && auth.userInfo && auth.userInfo.email) {
            const srchUsr = {
                email: auth.userInfo.email?.toLowerCase(),
            };
            onLoadFetchUser(srchUsr);
            onLoadFetchTA(srchUsr);
        }
    }, [auth, onLoadFetchUser, onLoadFetchTA]);

    return user.status === 'PENDING' ? (
        <Grid
            container
            wrap="nowrap"
            justify="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid item>
                <Grid container direction="column" className={classes.left}>
                    <Grid
                        item
                        component={Typography}
                        variant="h3"
                        className={classes.heading}
                    >
                        {REGISTER_SCREEN_HEADING}
                    </Grid>
                    <Grid
                        item
                        component={Typography}
                        className={classes.statement}
                        dangerouslySetInnerHTML={{
                            __html: REGISTER_PENDING_STATEMENT,
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    ) : user.status === 'NOT_AUTHORIZED' ? (
        <Grid
            container
            wrap="nowrap"
            justify="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid item>
                <Grid container direction="column" className={classes.left}>
                    <Grid
                        item
                        component={Typography}
                        variant="h3"
                        className={classes.heading}
                    >
                        {REGISTER_SCREEN_HEADING}
                    </Grid>
                    <Grid
                        item
                        component={Typography}
                        className={classes.statement}
                    >
                        {REGISTER_UNAUTHORIZED_STATEMENT}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ) : !user.email ? (
        <LoadingScreen />
    ) : (
        <Grid
            container
            wrap="nowrap"
            justify="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid item>
                <Grid container direction="column" className={classes.left}>
                    <Grid
                        item
                        component={Typography}
                        variant="h3"
                        className={classes.heading}
                    >
                        {REGISTER_SCREEN_HEADING}
                    </Grid>
                    <Grid
                        item
                        component={Typography}
                        className={classes.statement}
                    >
                        {REGISTER_REQUEST_STATEMENT}
                    </Grid>
                    <Grid
                        item
                        component={Typography}
                        className={classes.statement}
                        dangerouslySetInnerHTML={{
                            __html: REGISTER_SCREEN_STATEMENT_MORE,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item container justify="center" direction="column">
                <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    direction="column"
                >
                    <Grid item className={classes.registerButton}>
                        <Button
                            onClick={() => {
                                onClickRegister(user);
                            }}
                            disabled={loading}
                            loading={loading}
                            {...SubmitButtonProps}
                        >
                            {LABEL_REGISTER}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

RegisterScreen.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,
    onClickRegister: PropTypes.func,
    onLoadFetchUser: PropTypes.func,
    onLoadFetchTA: PropTypes.func,
    SubmitButtonProps: PropTypes.object,
    loading: PropTypes.bool,
    user: PropTypes.object,
    auth: PropTypes.object,
};

RegisterScreen.defaultProps = {
    onClickRegister: noop,
    onLoadFetchUser: noop,
    onLoadFetchTA: noop,
    loading: false,
    SubmitButtonProps: {},
    user: null,
    auth: null,
};

const StyledRegisterScreen = withStyles(styles)(RegisterScreen);
export default StyledRegisterScreen;

export const mapStateToProps = (state) => ({
    error: getError(state),
    user: getUser(state),
    auth: state.auth,
    SubmitButtonProps: {
        loading: isRegisterUserInProgress(state),
        disabled: isRegisterUserInProgress(state),
    },
});

export const mapDispatchToProps = (dispatch) => ({
    onLoadFetchUser: (userSearch) => dispatch(fetchUserRequested(userSearch)),
    onClickRegister: (user) => dispatch(registerUserRequested(user)),
    onLoadFetchTA: (userSearch) => dispatch(fetchTARequested(userSearch)),
});

export const ConnectedRegisterScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)((props) => (
    <ConnectedMainLayout
        FooterProps={{
            showPrivacyAct: false,
        }}
    >
        <StyledRegisterScreen {...props} />
    </ConnectedMainLayout>
));

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import dotLogo from './dot-logo.png';

const styles = (theme) => ({
    root: {
        height: 96,
        width: 116,
        paddingRight: 20,

        [theme.breakpoints.down('sm')]: {
            height: 65,
            width: 85,
        },
    },
});

const DotLogo = ({ classes }) => (
    <img
        src={dotLogo}
        alt="U.S. Department of Transportation"
        className={classes.root}
    />
);

DotLogo.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DotLogo);

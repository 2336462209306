import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import {
    PRIVACY_ACT_HEADING,
    PRIVACY_ACT_TEXT,
    OMB_CONTROL_NUMBER,
    EXPIRATION_DATE,
    SPACER,
} from '../../screens/LoginScreen/constants';

class PrivacyNotice extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid
                container
                direction="column"
                wrap="nowrap"
                className={classes.root}
            >
                <Grid item container className={classes.ombControlNumber}>
                    <Typography>
                        <strong>{OMB_CONTROL_NUMBER}</strong>
                    </Typography>
                    <Typography className={classes.spacer}>{SPACER}</Typography>
                    <Typography>{EXPIRATION_DATE}</Typography>
                </Grid>
                <h3>{PRIVACY_ACT_HEADING}</h3>
                <p>{PRIVACY_ACT_TEXT}</p>
            </Grid>
        );
    }
}

PrivacyNotice.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,
};

PrivacyNotice.defaultProps = {};

export default withStyles(styles)(PrivacyNotice);

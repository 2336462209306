import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
    Grid,
    Paper,
    TextareaAutosize,
    Typography,
    withStyles,
} from '@material-ui/core';
import { Check as SuccessIcon, Clear as FailIcon } from '@material-ui/icons';
import Button from '../../common/Button';
import PrintButton from '../../common/PrintButton';
import styles from './styles';
import { CSV_FILE_ERROR_CRITERIA, SUCCESS_TITLE } from './constants';
import { connect } from 'react-redux';
import { ConnectedMainLayout } from '../../layout/MainLayout';
import { getTokenFormData } from '../TokensForm/selectors';
import { ROUTE_HOME } from '../../routeConstants';
import { pluralize } from '../../utils/strings';

const TokensResultsScreen = (props) => {
    const {
        classes,
        title,
        resultsSummary,
        errors,
        errorsExceedThreshold,
        errorDisplayRows,
        buttonProps,
        buttonLabel,
        tokensWithErrorsCount,
        content,
    } = props;

    const [shouldRenderPrintHTML, setShouldRenderPrintHTML] = useState(false);
    const [printInProgress, setPrintInProgress] = useState(false);
    const failed = (errors && errors.length > 0) || errorsExceedThreshold;
    const failedBelowThreshold =
        errors && errors.length > 0 && !errorsExceedThreshold;

    const history = useHistory();

    const cleanup = () => {
        setShouldRenderPrintHTML(false);
        setPrintInProgress(false);
    };

    useEffect(() => {
        if (shouldRenderPrintHTML && !printInProgress) {
            setPrintInProgress(true);
            window.print();
            window.onafterprint = cleanup();
        }
    }, [shouldRenderPrintHTML, printInProgress]);

    return (
        <Grid container component={Paper} className={classes.root}>
            {title && (
                <Grid container item direction="row" justify="flex-end">
                    {shouldRenderPrintHTML ? (
                        <Grid item />
                    ) : (
                        <Grid
                            item
                            component={PrintButton}
                            onClick={() => {
                                setShouldRenderPrintHTML(true);
                            }}
                        />
                    )}
                </Grid>
            )}
            <Grid
                container
                direction="row"
                wrap="nowrap"
                spacing={2}
                className={classes.topGrid}
            >
                <Grid item>
                    {failed ? (
                        <FailIcon className={classes.failIcon} />
                    ) : (
                        title && <SuccessIcon className={classes.successIcon} />
                    )}
                </Grid>
                <Grid item container direction="column" spacing={4}>
                    <Grid
                        item
                        component={Typography}
                        color="primary"
                        className={classes.title}
                    >
                        {' '}
                        {title}{' '}
                    </Grid>
                    <Grid item component={Typography}>
                        {resultsSummary}
                        {title && (
                            <>
                                <br />
                                Please print this page for your records.
                            </>
                        )}
                    </Grid>
                    {title && failedBelowThreshold && (
                        <Grid container item direction="column" spacing={2}>
                            <Grid item component={Typography}>
                                {`${pluralize(
                                    'token',
                                    tokensWithErrorsCount,
                                )} had errors.`}
                            </Grid>
                            <Grid item>
                                <TextareaAutosize
                                    rowsMax={errorDisplayRows}
                                    rowsMin={errorDisplayRows}
                                    className={classes.errors}
                                    readonly
                                    disabled
                                >
                                    {errors.map((error) => error).join('\n')}
                                </TextareaAutosize>
                            </Grid>
                        </Grid>
                    )}
                    {errorsExceedThreshold && (
                        <Grid container item direction="column" spacing={2}>
                            <Grid item>
                                <Typography>
                                    {CSV_FILE_ERROR_CRITERIA}
                                </Typography>
                                <Typography className={classes.commonReasons}>
                                    Common reasons for CSV file errors:
                                </Typography>
                                <Typography
                                    className={classes.contentTypography}
                                >
                                    {content.map((contentItem, index) => (
                                        <li key={`csv-reason-${index}`}>
                                            {contentItem}
                                        </li>
                                    ))}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item container justify="flex-end" alignItems="flex-end">
                <Button
                    className={classes.button}
                    onClick={() => {
                        history.push(ROUTE_HOME);
                    }}
                    {...buttonProps}
                >
                    {buttonLabel}
                </Button>
            </Grid>
        </Grid>
    );
};

TokensResultsScreen.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Title to be displayed at the top of the component
    title: PropTypes.string,

    // Text to display as the results summary (below the title)
    resultsSummary: PropTypes.node,

    // Errors to show in the error box (if any)
    errors: PropTypes.arrayOf(PropTypes.string),

    // Indicate if the number of errors exceeded a threshold.
    errorsExceedThreshold: PropTypes.bool,

    // Count of tokens with errors
    tokensWithErrorsCount: PropTypes.number,

    // Control for the number of both min and max rows shown in the errors box.
    errorDisplayRows: PropTypes.number,

    // Props that will be spread onto the button.
    buttonProps: PropTypes.object,

    // Label to use on the button
    buttonLabel: PropTypes.node,

    content: PropTypes.arrayOf(PropTypes.string),
};

TokensResultsScreen.defaultProps = {
    title: SUCCESS_TITLE,
    resultsSummary: '',
    errors: [],
    errorsExceedThreshold: false,
    errorDisplayRows: 12,
    buttonProps: {},
    buttonLabel: 'Return to Home',
    tokensWithErrorsCount: 0,
    content: [
        'Incorrect TAID',
        'Duplicate tokens',
        'Content that is not tokens',
        'Incorrect token format- no TAID or incorrect amount of numeric characters',
        'Incorrect file was selected',
        'Instructions or other text in file',
    ],
};

const StyledTokensResultsScreen = withStyles(styles)(TokensResultsScreen);
export default StyledTokensResultsScreen;

const mapStateToProps = (state) => ({
    ...getTokenFormData(state),
});

export const ConnectedTokensResultsScreen = connect(mapStateToProps)(
    (props) => (
        <ConnectedMainLayout>
            <StyledTokensResultsScreen {...props} />
        </ConnectedMainLayout>
    ),
);

export default (theme) => {
    const ieMaxWidthXl = {
        [theme.breakpoints.down('xl')]: {
            maxWidth: 'calc(100vw - 17px)',
        },
    };
    return {
        root: {
            minHeight: '100vh',
        },
        runner: {
            backgroundColor: theme.palette.darkBlue,
            padding: '.75rem 5%',
        },
        header: {
            padding: '1rem 5%',
            backgroundColor: theme.palette.mediumBlue,
            borderBottom: '6px solid #B18708',
        },
        subHeader: {
            backgroundColor: theme.palette.primary.main,
        },
        nav: {
            marginBottom: 20,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        ieMaxWidthXl,
        appContent: {
            flexGrow: 1,
            '& > div': {
                alignSelf: 'center',
                flexGrow: 1,
            },
        },
        appContentContainer: {
            ...ieMaxWidthXl,
        },
        appContentLoggedOut: {
            backgroundColor: theme.palette.loginBlue,
        },
        idleBannerContainer: {
            marginTop: '2rem',
        },
        idleButton: {
            fontSize: 12,
            padding: '16px 50px',
        },
        version: {
            marginRight: '5%',
            marginBottom: 5,
            '& p': {
                fontSize: 14,
                fontStyle: 'italic',
            },
        },
        privacyNotice: {},
        footer: {
            backgroundColor: theme.palette.white,
        },
    };
};

export const PREFIX = 'db/';
export const REGISTER_USER_REQUESTED = `${PREFIX}REGISTER_USER_REQUESTED`;
export const REGISTER_USER_SUCCEEDED = `${PREFIX}REGISTER_USER_SUCCEEDED`;
export const REGISTER_USER_FAILED = `${PREFIX}REGISTER_USER_FAILED`;
export const FETCH_USER_REQUESTED = `${PREFIX}FETCH_USER_REQUESTED`;
export const FETCH_USER_SUCCEEDED = `${PREFIX}FETCH_USER_SUCCEEDED`;
export const FETCH_USER_FAILED = `${PREFIX}FETCH_USER_FAILED`;
export const FETCH_TA_REQUESTED = `${PREFIX}FETCH_TA_REQUESTED`;
export const FETCH_TA_SUCCEEDED = `${PREFIX}FETCH_TA_SUCCEEDED`;
export const FETCH_TA_FAILED = `${PREFIX}FETCH_TA_FAILED`;

export const registerUserRequested = (user) => ({
    type: REGISTER_USER_REQUESTED,
    user,
});

export const registerUserSucceeded = (user) => ({
    type: REGISTER_USER_SUCCEEDED,
    user,
});

export const registerUserFailed = (error) => ({
    type: REGISTER_USER_FAILED,
    error,
});

export const fetchUserRequested = (userSearch = {}) => ({
    type: FETCH_USER_REQUESTED,
    userSearch,
});

export const fetchUserSucceeded = (user) => ({
    type: FETCH_USER_SUCCEEDED,
    user,
});

export const fetchUserFailed = (error) => ({
    type: FETCH_USER_FAILED,
    error,
});

export const fetchTARequested = (userSearch = {}) => ({
    type: FETCH_TA_REQUESTED,
    userSearch,
});

export const fetchTASucceeded = (testAuthority) => ({
    type: FETCH_TA_SUCCEEDED,
    testAuthority,
});

export const fetchTAFailed = (error) => ({
    type: FETCH_USER_FAILED,
    error,
});

export default (theme) => {
    return {
        root: {
            height: '100%',
            paddingTop: '50px',
            paddingBottom: '10px',
        },
        heading: {
            fontWeight: 900,
            marginBottom: 20,
            fontSize: 54,

            [theme.breakpoints.down('md')]: {
                marginTop: 25,
                marginLeft: 20,
                fontSize: 32,
            },
        },
        activateSection: {
            backgroundColor: theme.palette.white,
            padding: 40,
            paddingTop: 20,
            paddingBottom: 20,

            [theme.breakpoints.down('md')]: {
                padding: 5,
                paddingTop: 10,
                paddingBottom: 10,
                justifyContent: 'center',
                flexDirection: 'column',
            },
        },
        activateLeadText: {
            fontSize: 20,
            maxWidth: 400,
            padding: '20px 10px',
        },
        activateButton: {
            padding: 20,
            paddingRight: 0,
        },
        registerButton: {
            padding: 20,
            paddingRight: 0,
        },
    };
};

import {
    INIT_STATUS_FAILED,
    INIT_STATUS_INITIALIZED,
    INIT_STATUS_INITIALIZING,
} from './constants';
import { CONFIG_VALUES_PULLED_AND_SET, INITIALIZATION_FAILED, INITIALIZATION_SUCCEEDED } from './actions';

export const initInitialState = {
    oktaConfig: {
        clientId: null,
        issuer: null,
        redirectUri: null,
        logoutUri: null,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        registartionIri:null
    },
    status: INIT_STATUS_INITIALIZING,
    errors: null,
};

export default function initReducer(state = initInitialState, action) {
    const { type } = action;

    if (type === CONFIG_VALUES_PULLED_AND_SET) {
        const { oktaConfig } = action
        return {
            ...state,
            oktaConfig: oktaConfig
        };
    } else if (type === INITIALIZATION_SUCCEEDED) {
        return {
            ...state,
            status: INIT_STATUS_INITIALIZED,
        };
    } else if (type === INITIALIZATION_FAILED) {
        return {
            ...state,
            status: INIT_STATUS_FAILED,
            errors: action.payload.errors,
        };
    }

    return state;
}

import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
    SUBMIT_TOKENS_REQUESTED,
    SUBMIT_TOKENS_SUCCEEDED,
    submitTokensFailed,
    submitTokensSucceeded,
} from './actions';
import { getValidTokens } from './selectors';
import { fetchWithAuth } from '../../store/api/sagas';
import { push } from 'react-router-redux';
import { ROUTE_RESULTS } from '../../routeConstants';
import { getUserName } from '../../auth/selectors';
import { getUserTaId } from '../../db/selectors';

export function* submitTokens() {
    try {
        const tokens = yield select(getValidTokens);
        const taId = yield select(getUserTaId);
        const createdBy = yield select(getUserName);
        const tokensData = {
            tokens,
            createdBy,
            taId,
        };
        const response = yield call(
            fetchWithAuth,
            `testAuthorities/${taId}/tokens`,
            {
                method: 'POST',
                body: tokensData,
            },
        );
        const { data: { items = [] } = {}, error = {} } =
            convertErrorFormat(response);
        yield put(submitTokensSucceeded(items, error.message || []));
    } catch (error) {
        yield put(submitTokensFailed(error));
    }
}

export default function* () {
    yield all([
        takeLatest(SUBMIT_TOKENS_REQUESTED, submitTokens),
        takeLatest(SUBMIT_TOKENS_SUCCEEDED, function* () {
            yield put(push(ROUTE_RESULTS));
        }),
    ]);
}

function convertErrorFormat(response) {
    const errorObj = { code: '', message: [] };

    if (
        response.error.message.errors &&
        response.error.message.errors.length > 0
    ) {
        response.error.message.errors.forEach((element) => {
            errorObj.message.push({
                token: element.value,
                messages: [element.message],
            });
        });
    }
    response.error = errorObj;
    return response;
}

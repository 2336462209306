import { call, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { configValuesPulledAndSet, initializationSucceeded } from './actions';
import oktaConfig from '../auth/oktaConfig';
import { AuthService } from '@okta/okta-react';
import { ROUTE_LOGIN } from "../routeConstants";

export default function* initSaga() {

    async function getOktaConfig() {
        const method = 'GET';
        const headers = { client: 'external', Accept: 'application/json'};
        const options = { method, headers };
        await fetch('/auth/extconfig', options)
        .then((response) => response.json())
        .then((data) => {
            oktaConfig.clientId = data.oktaClientId;
            oktaConfig.issuer = data.oktaIssuer;
            oktaConfig.redirectUri = data.oktaRedirectUri;
            oktaConfig.logoutUri = data.oktaLogoutUri;
            oktaConfig.registartionIri = data.oktaRegisterUri;
        })
    }   

    yield getOktaConfig();

    yield put(configValuesPulledAndSet(oktaConfig));

    const authService = yield new AuthService(oktaConfig);
    const authenticated = yield authService.isAuthenticated;
    if (authenticated) {
        try {
            yield call(authService.getUser);
        } catch (error) {
            yield call(authService.logout);
            yield put(push(ROUTE_LOGIN));
        }
    }
    
    yield put(initializationSucceeded());
}
